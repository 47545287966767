<template>
  <div>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Gerenciamento de Afiliados</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el label="Gerenciamento de Afiliados" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-toolbar class="q-pr-none q-mb-sm">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('filter_creatives') }}
        </q-tooltip></q-btn
      >
      <q-btn outline color="primary" icon-right="archive" class="q-mr-sm" no-caps @click="exportCsv()" unelevated>
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('export_csv') }}
        </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <q-input outlined label="Nome do Afiliado" debounce="700" v-model="params.name_affiliate" type="text" />
        </div>
        <div class="col-4">
          <q-input outlined label="Login do Afiliado" debounce="700" v-model="params.username_affiliate" type="text" />
        </div>
        <div class="col-4">
          <q-input outlined label="ID do Afiliado" debounce="700" v-model="params.id_affiliate" type="text" />
        </div>
        <div class="col-4">
          <span class="text-weight-medium q-mb-none">Status</span>
          <div class="q-gutter-xs">
            <q-radio v-model="params.status" val="1" keep-color label="Pendente" color="blue" class="text-blue" />
            <q-radio v-model="params.status" val="3" keep-color label="Ativo" color="positive" class="text-positive" />
            <q-radio v-model="params.status" val="4" keep-color label="Pausado" color="warning" class="text-warning" />
            <q-radio
              v-model="params.status"
              val="5"
              keep-color
              class="text-negative"
              label="Desativado"
              color="negative"
            />
          </div>
        </div>
        <div class="col-4">
          <q-select
            v-model="params.channel"
            use-input
            @filter="filterChannels"
            :options="channelsFiltered"
            label="Canais"
            outlined
            option-label="name"
            option-value="id"
          />
        </div>
        <div class="col-4">
          <date-input
            :v-model="params.date"
            outlined
            range
            label="Período de cadastro"
            clearable
            @update="params.date = $event"
          />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <!-- <q-btn
            color="positive"
            :label="$t('search')"
            no-caps
            unelevated
            class="q-px-md"
            @click="onFilterAffiliate"
          /> -->
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import ChannelService from '../../services/ChannelService';
export default {
  name: 'HeaderAffiliate',
  mixins: [AffiliateMixin, ChannelService],
  components: { DateInput },
  data() {
    return {
      filter: true,
      params: {
        id: null,
        id_affiliate: null,
        name_affiliate: null,
        status: null,
        username_affiliate: null,
        channel: null,
        offer: null,
        date: null
      },
      channels: [],
      channelsFiltered: []
    };
  },

  watch: {
    params: {
      handler() {
        this.onFilterAffiliate();
      },
      deep: true
    }
  },

  methods: {
    filterChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelsFiltered = this.channels;
        this.channelsFiltered = this.channels.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getListChannels();
        if (status === 200) {
          //console.log("channel: ", data);
          this.channels = data.map((item) => ({
            name: `${item.name} - (id: ${item.id})`,
            id: item.id
          }));
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    clearFilter() {
      for (let index in this.params) this.params[index] = null;
      this.ActionSetFilterAffiliate();
      this.getAffiliates();
    },
    onFilterAffiliate() {
      let filterSelect = [];
      if (this.params.name_affiliate) filterSelect.push(`Name=${this.params.name_affiliate}`);
      if (this.params.username_affiliate)
        filterSelect.push(`AffiliateEmail=${encodeURI(this.params.username_affiliate)}`);
      if (this.params.status) filterSelect.push(`status=${this.params.status}`);
      if (this.params.channel) filterSelect.push(`site_id=${this.params.channel.id}`);
      if (this.params.id_affiliate) filterSelect.push(`id=${this.params.id_affiliate}`);
      if (this.params.date) {
        let [from, to] = this.params.date.split(' - ');
        from = this.$moment(from, 'DD/MM/YYYY').format('YYYY-MM-DD');
        to = this.$moment(to, 'DD/MM/YYYY').format('YYYY-MM-DD');
        filterSelect.push(`CreateAt=${from}|${to}`);
      }

      this.ActionSetFilterAffiliate(filterSelect.join('%26'));
      this.getAffiliates();
    },

    async exportCsv() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=affiliate&path=/api/get/affiliate/search?${this.filterAffiliate}%26page=1%26perPage=99999`
        );
        if (status === 200) this.exportTableCsv(data.data, this.columns, 'afiliados');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.getChannels();
  }
};
</script>
