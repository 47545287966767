<template>
  <q-dialog v-model="dialogAffiliate" persistent>
    <q-card style="min-width: 600px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Informações de {{ affiliateName }} </q-toolbar-title>

        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>

      <q-form @submit="onSave">
        <q-card-section class="scroll q-col-gutter-md" style="max-height: 70vh">
          <div class="col-12">
            <q-btn unelevated round flat icon="mdi-pencil-outline" color="primary" @click="blockHistory = false" />

            <q-input
              v-model="param"
              outlined
              type="textarea"
              lazy-rules
              counter
              maxlength="2500"
              rows="15"
              :readonly="blockHistory"
            />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />

          <q-btn unelevated label="Salvar" no-caps padding="sm lg" color="primary" type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin';
import AffiliateService from '../../services/AffiliateService';

export default {
  name: 'FormHistory',

  mixins: [AffiliateMixin, AffiliateService],

  data() {
    return {
      param: '',
      history: false,
      blockHistory: false,
      affiliateName: String
    };
  },

  watch: {
    affiliateSelect() {
      if (this.affiliateSelect !== null) {
        this.onSetup();
      }
    }
  },

  methods: {
    async onSetup() {
      this.affiliateName = this.affiliateSelect.name;

      this.onLoading(true);

      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=affiliate&path=/api/affiliate_history/' + this.affiliateSelect.id
        );

        if (status === 200) {
          this.history = !!data.history;
          this.param = data.history;
          this.blockHistory = !!this.history;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onClose() {
      this.ActionSetDialogAffiliate(false);
    },
    onSave() {
      if (!this.param) this.onDelete();
      else if (this.history) this.onUpdate();
      else this.onCreate();
    },

    async onCreate() {
      this.onLoading(true);
      try {
        const { status } = await this.$http.post(`/v2/redirect`, {
          app: `affiliate`,
          path: '/api/affiliate_history/',
          params: {
            data: {
              affiliate_id: this.affiliateSelect.id,
              history: this.param
            }
          }
        });

        if (status === 201) this.successNotify('Histórico criado com sucesso!');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
        this.onClose();
      }
    },

    async onUpdate() {
      this.onLoading(true);
      try {
        const { status } = await this.$http.put(`/v2/redirect`, {
          app: `affiliate`,
          path: '/api/affiliate_history/',
          params: {
            data: {
              affiliate_id: this.affiliateSelect.id,
              history: this.param
            }
          }
        });

        if (status === 200) this.successNotify('Histórico editado com sucesso!');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
        this.onClose();
      }
    },

    async onDelete() {
      this.onLoading(true);
      try {
        const { status } = await this.$http.delete(
          '/redirect?app=affiliate&path=/api/affiliate_history/' + this.affiliateSelect.id
        );

        if (status === 200) this.successNotify('Histórico apagado com sucesso!');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
        this.onClose();
      }
    }
  }
};
</script>
