<template>
  <q-page padding>
    <header-affiliate />
    <list-affiliate />
    <form-history />
  </q-page>
</template>

<script>
import ListAffiliate from '../../components/affiliate/ListAffiliate.vue';
import HeaderAffiliate from '../../components/affiliate/HeaderAffiliate.vue';
import ChannelMixin from '../../mixins/ChannelMixin';
import FormHistory from '../../components/affiliate/FormHistory.vue';

export default {
  components: { HeaderAffiliate, ListAffiliate, FormHistory },
  name: 'Affiliate',
  mixins: [ChannelMixin]
};
</script>

<style></style>
